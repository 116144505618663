import CashbackCalcForm from './cashback-calc-form/cashback-calc-form.vue';
import cashbackApi from '../../api/cashback-api.js';
import { BONUS_ANNUAL_CAP, BONUS_GOLD_ANNUAL_CAP, BONUS_MONTHLY_CAP, BONUS_GOLD_MONTHLY_CAP } from './cashback-calc-constants';
import VueFormNotification from '@ui-common/vue-form-notification';

export default {
	data: () => ({
		technicalError: false,
		bonusRefund: null,
		bonusGoldRefund: null,
		bonusEntry: null,
		bonusEntryText: null
	}),
	props: {
		cashbackTitle: {}
	},
	components: {
		CashbackCalcForm,
		VueFormNotification
	},
	computed: {
		bonusAnnualCap() {
			return BONUS_ANNUAL_CAP;
		},
		bonusGoldAnnualCap() {
			return BONUS_GOLD_ANNUAL_CAP;
		},
		bonusMonthlyCap() {
			return BONUS_MONTHLY_CAP;
		},
		bonusGoldMonthlyCap() {
			return BONUS_GOLD_MONTHLY_CAP;
		}
	},
	methods: {
		setBonusRefund(bonusRefund) {
			this.bonusRefund = bonusRefund;
		},
		setBonusGoldRefund(bonusGoldRefund) {
			this.bonusGoldRefund = bonusGoldRefund;
		},
		openPrecalculation(cardId) {
			this.$parent.$emit('open-precalculation', cardId);
		},
		sendGtm(cashbackCardName, cashbackRate, refundType) {
			this.$gtm.trackEvent({
				event: 'cashbackCalcRequest',
				cashbackCardName,
				cashbackRefundAmount: this[refundType],
				cashbackRate
			});
		},
		handleCardOrder(cashbackCardName, cashbackRate, refundType, openPrecalculation) {
			return () => {
				return new Promise((resolve) => {
					this.sendGtm(cashbackCardName, cashbackRate, refundType);
					if (openPrecalculation) {
						this.openPrecalculation(cashbackCardName);
					}
					resolve();
				});
			};
		},
		sendBonusGoldGtm() {
			return new Promise((resolve) => {
				this.$gtm.trackEvent({
					event: 'cashbackCalcRequest',
					cashbackCardName: 'Bonus Gold',
					cashbackRefundAmount: this.bonusGoldRefund,
					cashbackRate: '39,00'
				});
				resolve();
			});
		},
		async getBonus() {
			try {
				const response = await cashbackApi.getBonus();
				this.bonusEntry = response.data.bonusAmount;
				this.bonusEntryText = response.data.bonusModalText;
			} catch (err) {
				this.technicalError = true;
			}
		}
	},
	created() {
		this.getBonus();
	}
};
