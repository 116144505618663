export const ALL_DEFAULT = 200000;
export const SPRING_DEFAULT = 40000;
export const SUMMER_DEFAULT = 100000;
export const AUTUMN_DEFAULT = 50000;
export const WINTER_DEFAULT = 30000;
export const BONUS_MONTHLY_CAP = 2500;
export const BONUS_GOLD_MONTHLY_CAP = 4200;
export const BONUS_ANNUAL_CAP = 30000;
export const BONUS_GOLD_ANNUAL_CAP = 50000;
export const BONUS_GOLD_PERCENT_CHANGE = 3000000;
