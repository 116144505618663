import VueSlider from '@ui-common/vue-slider';
import VueCurrencyInput from '@ui-common/vue-currency-input';
import { longClickDirective } from 'vue-long-click';
const longClick = longClickDirective({ delay: 400, interval: 10 });
const roundToInterval = (amount, interval) => Math.round(amount / interval) * interval;
const handleMinMax = (amount, min, max) => Math.min(Math.max(amount, min), max);
const MAX = 500000;
export default {
	directives: {
		longClick
	},
	data() {
		return {
			model: {
				amount: null
			},
			inputAmount: null
		};
	},
	props: {
		id: {
			required: true,
			type: String
		},
		interval: {
			required: true,
			type: Number
		},
		value: {
			required: true
		},
		src: {
			required: true,
			type: String
		},
		error: {
			required: false,
			type: Boolean
		},
		errorId: {
			required: false,
			type: String
		}
	},
	watch: {
		['model.amount']: {
			immediate: true,
			handler(value) {
				this.inputAmount = value;
				this.$emit('input', value);
			}
		},
		value: {
			handler(value) {
				this.model.amount = value;
			},
			immediate: true
		}
	},
	components: {
		VueSlider,
		VueCurrencyInput
	},
	computed: {
		max() {
			return MAX;
		},
		ariaDescribe() {
			let errorId = this.error ? ' ' + this.errorId : '';
			return 'calculator-slider__label_' + this.id + errorId;
		}
	},
	methods: {
		onAmountBlur() {
			this.model.amount = handleMinMax(roundToInterval(this.inputAmount, this.interval), 0, MAX);
			this.inputAmount = this.model.amount;
		},
		onKeyPress(e) {
			if (e.which == 13) {
				this.onAmountBlur();
			}
		}
	}
};
