import CalculatorSlider from '../calculator-slider/calculator-slider.vue';
import VueFormNotification from '@ui-common/vue-form-notification';
import {
	ALL_DEFAULT,
	SPRING_DEFAULT,
	SUMMER_DEFAULT,
	AUTUMN_DEFAULT,
	WINTER_DEFAULT,
	BONUS_MONTHLY_CAP,
	BONUS_ANNUAL_CAP,
	BONUS_GOLD_PERCENT_CHANGE,
	BONUS_GOLD_MONTHLY_CAP,
	BONUS_GOLD_ANNUAL_CAP
} from '../cashback-calc-constants';

export default {
	data() {
		return {
			model: {
				all: ALL_DEFAULT,
				spring: SPRING_DEFAULT,
				summer: SUMMER_DEFAULT,
				autumn: AUTUMN_DEFAULT,
				winter: WINTER_DEFAULT
			}
		};
	},
	props: {
		bonusEntry: {
			required: true
		}
	},
	watch: {
		bonusAnnual: {
			handler(val) {
				this.$emit('bonus-refund-change', val);
			},
			immediate: true
		},
		bonusGoldAnnual: {
			handler(val) {
				this.$emit('bonus-gold-refund-change', val);
			},
			immediate: true
		},
		'model.all'(val, oldVal) {
			if (typeof val != 'undefined' && val != null && oldVal != null) {
				this.$gtm.trackEvent({
					event: 'cashbackCalcTotalSpending',
					cashbackTotalSpending: val
				});
			}
		},
		'model.spring'(val, oldVal) {
			if (typeof val != 'undefined' && val != null && oldVal != null) {
				this.$gtm.trackEvent({
					event: 'cashbackCalcClothingSpending',
					cashbackClothingSpending: val
				});
			}
		},
		'model.summer'(val, oldVal) {
			if (typeof val != 'undefined' && val != null && oldVal != null) {
				this.$gtm.trackEvent({
					event: 'cashbackCalcHolidaySpending',
					cashbackHolidaySpending: val
				});
			}
		},
		'model.autumn'(val, oldVal) {
			if (typeof val != 'undefined' && val != null && oldVal != null) {
				this.$gtm.trackEvent({
					event: 'cashbackCalcITSpending',
					cashbackITSpending: val
				});
			}
		},
		'model.winter'(val, oldVal) {
			if (typeof val != 'undefined' && val != null && oldVal != null) {
				this.$gtm.trackEvent({
					event: 'cashbackCalcEntertainmentSpending',
					cashbackEntertainmentSpending: val
				});
			}
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		tooMuch() {
			return this.model.all < Math.max(this.model.spring, this.model.summer, this.model.autumn, this.model.winter);
		},
		bonusAnnual() {
			let allMonthly = this.model.all / 100;
			let springMonthly = Math.min(BONUS_MONTHLY_CAP, allMonthly + (this.model.spring * 2) / 100);
			let summerMonthly = Math.min(BONUS_MONTHLY_CAP, allMonthly + (this.model.summer * 2) / 100);
			let autumnMonthly = Math.min(BONUS_MONTHLY_CAP, allMonthly + (this.model.autumn * 2) / 100);
			let winterMonthly = Math.min(BONUS_MONTHLY_CAP, allMonthly + (this.model.winter * 2) / 100);
			return Math.min(BONUS_ANNUAL_CAP, springMonthly * 3 + summerMonthly * 3 + autumnMonthly * 3 + winterMonthly * 3 + this.bonusEntry);
		},
		bonusGoldAnnual() {
			let monthlyAggregated = 0;
			let months = [];
			for (let i = 0; i < 12; i++) {
				monthlyAggregated += this.model.all;
				let allMonthly;
				if (monthlyAggregated > BONUS_GOLD_PERCENT_CHANGE) {
					let overLimit = monthlyAggregated - BONUS_GOLD_PERCENT_CHANGE;
					let underLimit = this.model.all - overLimit;
					allMonthly = underLimit / 100 + (overLimit * 2) / 100;
				} else {
					allMonthly = this.model.all / 100;
				}
				let seasonMonthly;
				switch (i) {
					case 0:
					case 1:
					case 11:
						seasonMonthly = this.model.winter;
						break;
					case 2:
					case 3:
					case 4:
						seasonMonthly = this.model.spring;
						break;
					case 5:
					case 6:
					case 7:
						seasonMonthly = this.model.summer;
						break;
					case 8:
					case 9:
					case 10:
						seasonMonthly = this.model.autumn;
						break;
					default:
				}
				let monthly = Math.min(BONUS_GOLD_MONTHLY_CAP, allMonthly + (seasonMonthly * 2) / 100);
				months.push(monthly);
			}
			return Math.min(BONUS_GOLD_ANNUAL_CAP, this.bonusEntry + months.reduce((acc, value) => acc + value, 0));
		}
	},
	components: {
		CalculatorSlider,
		VueFormNotification
	}
};
